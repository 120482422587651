import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import TranslationPage from './pages/TranslationPage'; // Import the Translation Page
import UserGuide from './pages/UserGuide'; // Import the User Guide
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/translation" element={<TranslationPage />} />
                <Route path="/userguide" element={<UserGuide />} /> {/* User Guide Route */}
            </Routes>
        </Router>
    );
}

export default App;
