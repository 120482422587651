import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // React Router for navigation

function TranslationPage() {
    const navigate = useNavigate();

    // Function to navigate back to the home/termbase page
    const handleBackToTermbase = () => {
        navigate('/'); // Redirect to Home/Termbase
    };

    return (
        <Box style={{ display: 'flex', padding: '2rem', justifyContent: 'center', textAlign: 'center' }}>
            {/* Left Side: Text Instructions */}
            <Box style={{ width: '40%', textAlign: 'left', paddingRight: '2rem' }}>
                <Typography variant="h6" gutterBottom>
                    How to Submit a Translation Request 📝
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Upload the Text or File to be Translated 📂</strong>
                    <br />
                    What to Upload: Just the content you need translated—no comments, no instructions.
                    <br />
                    File Types: You can upload text files, documents, or any supported formats.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    💡 <strong>Remember:</strong> The file or text you upload should ONLY contain the phrases or content you want translated. Keep it clean and free of any extra notes or comments.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Use the Notes Section for Additional Info ✏️</strong>
                    <br />
                    Have specific instructions or comments for the translator? Great! Drop them in the Notes section.
                    <br />
                    This is where you can clarify any special requirements, like tone, terminology preferences, or anything else the translator should know.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    And that's it! 🎉 Once your request is submitted, the translators will get to work on it. Keeping everything organized like this helps them focus on delivering a fast and accurate translation for you.
                </Typography>
            </Box>

            {/* Right Side: Submitter Portal Frame */}
            <Box>
                <iframe
                    width="530px"
                    height="400px"
                    frameBorder="0"
                    src="https://cloud.memsource.com/web/translate?context=267001-Iap1K-VwB4a&embedded=true"
                    title="Translation Frame"
                ></iframe>

                <br /><br />

                {/* Button to go back to Home */}
                <Button variant="contained" color="primary" onClick={handleBackToTermbase}>
                    Back to Termbase
                </Button>
            </Box>
        </Box>
    );
}

export default TranslationPage;
