import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import yes5 from '../components/yes5.png';
function UserGuide() {
    const navigate = useNavigate();

    // Function to navigate back to the home/termbase page
    const handleBackToTermbase = () => {
        navigate('/'); // Redirect to Home/Termbase
    };

    return (
        <Box style={{ padding: '2rem', maxWidth: '800px', margin: '0 auto' }}>
            <Typography variant="h4" gutterBottom>
                Welcome to your Termbase! 🎉
            </Typography>

            {/* Adding the Image */}
            <Box style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={yes5} alt="Yes Image" style={{ maxWidth: '20%', height: 'auto' }} />
            </Box>

            <Typography variant="body1" paragraph>
                This quick guide will walk you through the main features of your termbase, helping you get the most out of it. Whether you’re adding new terms, searching for translations, or uploading files, we've got you covered. Let’s get started! 🚀
            </Typography>

            <Typography variant="h5" gutterBottom>
                1. Adding New Terms 📋
            </Typography>
            <Typography variant="body1" paragraph>
                Want to add new terms to the database? It’s super easy!
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: Scroll down to the Add Term section.</li>
                <li>Step 2: Fill in the term details, and hit that button! 💪</li>
                <li>Step 3: You'll get a success message once your term is added. If you're on a roll, keep adding more!</li>
            </Typography>

            <Typography variant="h5" gutterBottom>
                2. Searching for Terms 🔍
            </Typography>
            <Typography variant="body1" paragraph>
                Need to look up existing terms? Here's how:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: Head over to the Search Terms section.</li>
                <li>Step 2: Type your search query in the search bar.</li>
                <li>Step 3: Click the search button to see your results instantly.</li>
            </Typography>
            <Typography variant="body1" paragraph>
                💡 <strong>Tip:</strong> If no results are found, don't worry! A message will pop up to let you know.
            </Typography>

            <Typography variant="h5" gutterBottom>
                3. Viewing and Commenting on Terms 💬
            </Typography>
            <Typography variant="body1" paragraph>
                Found the term you were looking for? You can easily check the details and even leave comments!
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: In the Term List section, click on any term to select it.</li>
                <li>Step 2: Once selected, a comment form will appear. Type in your feedback, thoughts, or additional info about the term.</li>
                <li>Step 3: Hit submit, and your comment will be saved!</li>
            </Typography>

            <Typography variant="h5" gutterBottom>
                4. Status Updates ⚙️
            </Typography>
            <Typography variant="body1" paragraph>
                You can easily update the status of terms (e.g., mark them as preferred, newest, etc.).
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: Select the term whose status you want to update from the Term List.</li>
                <li>Step 2: Use the options to change its status accordingly.</li>
            </Typography>

            <Typography variant="h5" gutterBottom>
                5. Translating Text 🌐
            </Typography>
            <Typography variant="body1" paragraph>
                Need a quick translation using the terms in the database? We’ve got that covered!
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: Go to the Translate Text section.</li>
                <li>Step 2: Select the product and language you want to translate to, and watch the magic happen! ✨</li>
                <li>Step 3: Your translated text will appear below.</li>
            </Typography>

            <Typography variant="h5" gutterBottom>
                6. Uploading Excel Files 📂
            </Typography>
            <Typography variant="body1" paragraph>
                Want to add multiple terms at once? Upload your file and let Herbie do the heavy lifting!
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: Head over to the Upload Excel File section.</li>
                <li>Step 2: Follow the instructions, select your file, and hit upload.</li>
                <li>Step 3: You’ll get a message once your terms are successfully uploaded.</li>
            </Typography>

            <Typography variant="h5" gutterBottom>
                7. Requesting Translations 💬
            </Typography>
            <Typography variant="body1" paragraph>
                Looking for help with translations? You can easily request one.
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: Hit the Request Translation button at the top-right corner of the page.</li>
                <li>Step 2: You’ll be taken to a special page where you can submit your translation requests. Just follow the instructions on the page</li>
            </Typography>

            <Typography variant="h5" gutterBottom>
                8. Signing Out 🚪
            </Typography>
            <Typography variant="body1" paragraph>
                When you're done, signing out is just a click away.
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Step 1: Scroll down and click the Sign Out button.</li>
            </Typography>

            <Typography variant="body1" paragraph>
                That’s it! You’re all set to manage your termbase like a pro. Have fun using the app, and don’t forget to explore all its features! ✨🎉
            </Typography>
            <Typography variant="body1" paragraph>
                Happy term-hunting! 🐢💻
            </Typography>

            {/* Button to go back to Home */}
            <Button variant="contained" color="primary" onClick={handleBackToTermbase}>
                Back to Termbase
            </Button>
        </Box>
    );
}

export default UserGuide;
