// Home.js
import React, { useState, useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import TermList from '../components/TermList';
import AddTermForm from '../components/AddTermForm';
import MascotWithTooltip from '../components/MascotWithTooltip';
import TurtleMascot from '../components/TurtleMascot';
import { Typography, Box, Button } from '@mui/material';

import magnify from '../components/magnify.png';
import dict from '../components/dict.png';
import notes from '../components/notes.png';
import welcomes from '../components/welcomes.png';

function Home() {
    const [terms, setTerms] = useState([]);
    const [filteredTerms, setFilteredTerms] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchTerms();
    }, []);

    const fetchTerms = async () => {
        try {
            const response = await axios.get('https://api.herbieterm.com/terms', {
                withCredentials: true
            });
            setTerms(response.data);
            setFilteredTerms(response.data);
        } catch (error) {
            setErrorMessage('Error fetching terms. Please try again later.');
        }
    };

    const handleSearch = (searchValue) => {
        if (!searchValue) {
            setFilteredTerms(terms);
            setErrorMessage('');
            return;
        }

        const filtered = terms.filter(term =>
            Object.values(term.translations || {}).some(t =>
                t?.toLowerCase().includes(searchValue.toLowerCase())
            )
        );

        setFilteredTerms(filtered);
        if (filtered.length === 0) {
            setErrorMessage(`No results found for "${searchValue}"`);
            setTimeout(() => setErrorMessage(''), 5000);
        } else {
            setErrorMessage('');
        }
    };

    const handleTermAdded = async () => {
        await fetchTerms();
    };

    return (
        <Authenticator
            components={{
                Header() {
                    return (
                        <Box component="img" src={welcomes} alt="Welcome" sx={{ width: 230, margin: '0 auto 60px' }} />
                    );
                },
            }}
        >
            {({ signOut }) => (
                <div style={{ padding: '2rem', position: 'relative' }}>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    
                    {/* Request Translation Button */}
                    <Box sx={{ position: 'absolute', top: '20px', right: '320px', zIndex: 10 }}>
                        <Button variant="contained" color="secondary" onClick={() => navigate('/translation')}>
                            Request translation
                        </Button>
                    </Box>

                    {/* User Guide Button */}
                    <Box sx={{ position: 'absolute', top: '20px', right: '180px', zIndex: 10 }}>
                        <Button variant="contained" color="primary" onClick={() => navigate('/userguide')}>
                            User Guide
                        </Button>
                    </Box>

                    {/* Sign Out Button */}
                    <Box sx={{ position: 'absolute', top: '20px', right: '20px', zIndex: 10 }}>
                        <Button variant="contained" color="primary" onClick={signOut}>
                            Sign out
                        </Button>
                    </Box>

                    {/* Main Content */}
                    <Box display="flex" alignItems="center" mb={2} mt={4}>
                        <Typography variant="h5" gutterBottom fontWeight="bold" mr={1}>Search Terms</Typography>
                        <MascotWithTooltip instruction="Search for terms in the database." mascotImage={magnify} />
                    </Box>
                    <SearchBar searchTerm={searchTerm} onSearchTermChange={setSearchTerm} onSearchClick={() => handleSearch(searchTerm)} />

                    <Box display="flex" alignItems="center" mt={2} mb={2}>
                        <Typography variant="h5" gutterBottom fontWeight="bold" mr={1}>Term List</Typography>
                        <MascotWithTooltip instruction="Displays all terms." mascotImage={dict} />
                    </Box>
                    <TermList terms={filteredTerms} />

                    <Box display="flex" alignItems="center" mt={2} mb={2}>
                        <Typography variant="h5" gutterBottom fontWeight="bold" mr={1}>Add Term</Typography>
                        <MascotWithTooltip instruction="Add new terms to the database." mascotImage={notes} />
                    </Box>
                    <AddTermForm onTermAdded={handleTermAdded} />
                </div>
            )}
        </Authenticator>
    );
}

export default Home;
