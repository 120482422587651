// CommentList.js
import React, { useState } from 'react';
import { Box, TextField, Button, Typography, List, ListItem } from '@mui/material';
import axios from 'axios';

function CommentList({ termId, comments, onCommentAdded }) {
    const [commentText, setCommentText] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [language, setLanguage] = useState('');

    const handleAddComment = async () => {
        if (!commentText || !createdBy || !language) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            const response = await axios.post(`https://api.herbieterm.com/terms/${termId}/comments`, {
                text: commentText,
                createdBy,
                language,
            });

            // Reset input fields
            setCommentText('');
            setCreatedBy('');
            setLanguage('');

            // Update comments with the new one
            onCommentAdded(response.data);
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>Comments</Typography>
            <List>
                {comments.map((comment, index) => (
                    <ListItem key={index}>
                        <Typography variant="body1">{comment.language.toUpperCase()}: {comment.text} - <strong>{comment.createdBy}</strong></Typography>
                    </ListItem>
                ))}
            </List>

            <TextField
                label="Your Name"
                fullWidth
                margin="normal"
                value={createdBy}
                onChange={(e) => setCreatedBy(e.target.value)}
            />
            <TextField
                label="Language"
                fullWidth
                margin="normal"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
            />
            <TextField
                label="Comment"
                fullWidth
                margin="normal"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddComment}
                style={{ marginTop: '10px' }}
            >
                Add Comment
            </Button>
        </Box>
    );
}

export default CommentList;
