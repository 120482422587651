// TermList.js
import React, { useState } from 'react';
import { 
    Paper, Typography, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Tooltip, Box, TablePagination, MenuItem, 
    Select, Collapse, IconButton, Badge 
} from '@mui/material';
import CommentList from './CommentList';
import { ExpandMore, ExpandLess, Comment } from '@mui/icons-material';

function TermList({
    terms = [],
    setTerms,
    onTermSelect = () => {}, // Default to no-op function
    selectedTermId,
    onStatusChange = () => {} // Default to no-op function
}) {
    const [expandedTerm, setExpandedTerm] = useState(null);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const handleTermClick = (termId, language) => {
        if (termId) onTermSelect(termId, language);
    };

    const handleExpandClick = (termId) => {
        if (termId) setExpandedTerm(expandedTerm === termId ? null : termId);
    };

    const handleChangePage = (event, newPage) => setPage(newPage);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Preferred': return '#cdf7e0';
            case 'Outdated': return '#f2cfce';
            case 'Newest': return '#c6ebf7';
            default: return 'transparent';
        }
    };

    return (
        <Paper style={{ padding: '1rem' }}>
            <Typography variant="h6" gutterBottom>Term List</Typography>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell>English</TableCell>
                            <TableCell>Danish</TableCell>
                            <TableCell>Finnish</TableCell>
                            <TableCell>Icelandic</TableCell>
                            <TableCell>Norwegian</TableCell>
                            <TableCell>Swedish</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Comments</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {terms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => (
                            <React.Fragment key={term._id}>
                                <TableRow 
                                    style={{
                                        backgroundColor: selectedTermId === term._id ? '#f0f8ff' : getStatusColor(term.status),
                                        cursor: 'pointer'
                                    }}
                                >
                                    <TableCell>{term.productName || 'N/A'}</TableCell>
                                    {['en', 'da', 'fi', 'is', 'no', 'sv'].map((lang) => (
                                        <Tooltip 
                                            key={`${term._id}-${lang}`} 
                                            title={
                                                term.comments?.filter(comment => comment.language === lang)
                                                    .map(comment => comment.text || '')
                                                    .join(', ') || ''
                                            }
                                        >
                                            <TableCell onClick={() => handleTermClick(term._id, lang)}>
                                                {term.translations?.[lang] || 'N/A'}
                                            </TableCell>
                                        </Tooltip>
                                    ))}
                                    <TableCell>
                                        <Select 
                                            value={term.status || ''} 
                                            onChange={(e) => onStatusChange(term._id, e.target.value)} 
                                            displayEmpty
                                        >
                                            <MenuItem value="">No Status</MenuItem>
                                            <MenuItem value="Confirmed">Confirmed ✅</MenuItem>
                                            <MenuItem value="Outdated">Outdated 🚫</MenuItem>
                                            <MenuItem value="Pending">Pending ⏳</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleExpandClick(term._id)}>
                                            <Badge badgeContent={term.comments?.length || 0} color="primary">
                                                <Comment color={term.comments?.length ? 'action' : 'disabled'} />
                                            </Badge>
                                            {expandedTerm === term._id ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                        <Collapse in={expandedTerm === term._id} timeout="auto" unmountOnExit>
                                            <Box margin={2}>
                                                <CommentList
                                                    termId={term._id}
                                                    comments={term.comments || []}
                                                    onCommentAdded={(updatedTerm) => {
                                                        setTerms(prevTerms => prevTerms.map(t => t._id === updatedTerm._id ? updatedTerm : t));
                                                    }}
                                                />
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination 
                component="div" 
                count={terms.length} 
                page={page} 
                onPageChange={handleChangePage} 
                rowsPerPage={rowsPerPage} 
                rowsPerPageOptions={[rowsPerPage]} 
            />
        </Paper>
    );
}

export default TermList;
