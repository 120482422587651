/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:15057fa8-9d74-496a-8892-0bca00b29fa3",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_pXr8UgOse",
    "aws_user_pools_web_client_id": "3d4bc1g2gm2eb7fk8efglqth3k",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "backnewdayresource",
            "endpoint": "https://jwk124sl9i.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "Auth": {
        "region": "eu-central-1",
        "userPoolId": "eu-central-1_T1TunXsr3",
        "userPoolWebClientId": "2kt83c8oq5r709347sh4pq1ne8",
        "mandatorySignIn": true
    }
};


export default awsmobile;
